import RewardsBrandingCupIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingCupIcon.svelte";
import RewardsBrandingGiftIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingGiftIcon.svelte";
import RewardsBrandingRibbonIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingRibbonIcon.svelte";
import RewardsBrandingStarIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingStarIcon.svelte";
import RewardsBrandingTicketIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingTicketIcon.svelte";
//Variables
import { REWARDS_BRANDING_WIDGET_ICON_TYPES } from "@modules/branding/constants";

export const RTL_DIRECTION = "rtl";
export const REMOVE_PROTOCOL_REGEX = /^https?:\/\//;
export const REFERRAL_SHARE_PATH = "referral";
export const REFERRAL_SHARE_CHANNELTYPE = "channelType";
export const POINTS_SHARE_PATH = "points";
export const EARNING_RULE_SHARE_RULE_ID = "ruleId";
export const EARNING_RULE_SHARE_EVENT_TYPE = "eventType";
export const SOCIAL_SHARE_CHANNELNAME = "channelName";
export const TOKEN = "token";
export const REWARDS_STOREFRONT_BASE_URL =
    "/apps/ssw/storefront-api/rewards-storefront/v2";
export const PAGES_URL = "/pages";
export const COLLECTIONS_ALL_PAGE_URL = "/collections/all";
export const COLLECTIONS_PAGE_URL = "/collections";
export const SHARE_REVIEW_PAGE_URL =
    "/apps/ssw/collect-review?x-gw-current-app=default";
export const LOGIN_PAGE_URL = "/account/login";
export const REGISTER_PAGE_URL = "/account/register";
export const PRODUCT_VARIANT_DEFAULT_TITLE = "Default Title";
export const SHARE_LINK = "{{ share_link }} ";
export const VERIFY_ACCOUNT_MODAL_NAME = "verify-account-modal";
export const JOIN_NOW_MODAL_NAME = "join-now-modal";
export const DISCOUNT_COOKIE_KEY = "discount_code";
export const REFERRAL_CONTEXT_STORAGE_KEY = "gwReferralContext";
export const REWARDS_HOME_ANCHOR = "rewards-home";
export const PERCENTAGE_SYMBOL = "%";
export const MAIL_TO_BODY = "mailto:?body=";

//Enums
export enum KeyBoardEventKeys {
    ArrowDown = "ArrowDown",
    ArrowUp = "ArrowUp",
    Space = "Space",
    Escape = "Escape",
    Enter = "Enter",
}

export enum RewardsWidgetsPlaceholders {
    rewardsPopUpWidgetWrapperPlaceholder = ".gw-widget-placeholder",
    rewardsPopUpRootWidgetPlaceholder = ".gw-rd-popup-root-widget-placeholder",
    rewardsJoinNowModalWidgetPlaceholder = "body",
    rewardsVerifyAccountModalWidgetPlaceholder = "body",
    rewardsWaysToEarnPageWidgetPlaceholder = ".gw-rd-ways-to-earn-page-widget-placeholder",
    rewardsWaysToEarnPageWidgetModalPlaceholder = "body",
    rewardsWaysToSpendPageWidgetPlaceholder = ".gw-rd-ways-to-spend-page-widget-placeholder",
    rewardsWaysToSpendPageWidgetModalPlaceholder = "body",
    rewardsReferralProgramPageWidgetPlaceholder = ".gw-rd-referral-program-page-widget-placeholder",
    rewardsCustomerRewardsModalPlaceholder = "body",
    rewardsVipTiersPageWidgetPlaceholder = ".gw-rd-vip-tiers-page-widget-placeholder",
    rewardsEarnPointsProductPagePlaceholder = ".gw-rd-earn-points-product-page-widget-placeholder",
    rewardsEmptyStatePageWidgetPlaceholder = ".gw-rd-page-wrapper",
}

export enum RewardsTranslationNamespaces {
    commonCriticalPoints = "common.critical.Points",
    rewardsWaysToEarnPageWidget = "rewards.RewardsWaysToEarnPageWidget",
    rewardsWaysToSpendPageWidget = "rewards.RewardsWaysToSpendPageWidget",
    rewardsVipTiersPageWidget = "rewards.RewardsVipTiersPageWidget",
    rewardsReferralProgramPageWidget = "rewards.RewardsReferralProgramPageWidget",
    rewardsEmptyStatePageWidget = "rewards.RewardsEmptyStatePageWidget",
    rewardsCriticalEarnPointsProductPageWidget = "rewards.critical.RewardsEarnPointsProductPageWidget",
}

//Do not delete or edit enum REWARDS_DATA_GW_SELECTORS
export enum REWARDS_DATA_GW_SELECTORS {
    earningRulePopup = "gw-earning-rule-popup",
    earningRulePage = "gw-earning-rule-page",
    earningRulePageModal = "gw-earning-rule-page-modal",
    spendingRulePopup = "gw-spending-rule-popup",
    spendingRulePage = "gw-spending-rule-page",
    spendingRulePageModal = "gw-spending-rule-page-modal",
    tierPopup = "gw-tier-popup",
    tierPage = "gw-tier-page",
    customerRewardsPopup = "gw-customer-rewards-popup",
    customerRewardsPage = "gw-customer-rewards-page",
    customerRewardsPageModal = "gw-customer-rewards-page-modal",
}

export const REWARDS_BRANDING_ICONS = {
    [REWARDS_BRANDING_WIDGET_ICON_TYPES.default]: RewardsBrandingCupIcon,
    [REWARDS_BRANDING_WIDGET_ICON_TYPES.gift]: RewardsBrandingGiftIcon,
    [REWARDS_BRANDING_WIDGET_ICON_TYPES.ticket]: RewardsBrandingTicketIcon,
    [REWARDS_BRANDING_WIDGET_ICON_TYPES.star]: RewardsBrandingStarIcon,
    [REWARDS_BRANDING_WIDGET_ICON_TYPES.ribbon]: RewardsBrandingRibbonIcon,
};
